let compendiumRow={
  MCMapContainer: "CR-Container",
  MCMapSection: "CR-Map-Section",
  MCLink: "CR-Link",
  MCCell: "CR-Cell",
  MCCustomDelItem: "CR-Custom-Del-Item",
  MCDelItem: "CR-Del-Item",
  MCDelImgItem: "CR-Del-imgItem",
  MCCustomEditItem: "CR-Custom-Edit-Item",
  MCEditImgItem: "CR-Edit-imgItem",
  MCEditItem: "CR-Edit-Item",
  MCAttributeItem: "CR-Attribute-Item",
  MCImgItem: "CR-imgItem",
  MCTextItem: "CR-Text-Item",


}
export {compendiumRow}